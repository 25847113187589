import React, { useState } from "react";
import { Configure } from "react-instantsearch-dom";
import GlobalSearchResults from "./GlobalSearchResults";
import Media from "react-media";
import styled from "styled-components";
import SearchBox from "./SearchBox";
import OutsideClickHandler from "react-outside-click-handler";
import SearchInstance from "./SearchInstance";
import DynamicIcon from "../Ant/DynamicIcon";

const Wrapper = styled.div`
    @media (max-width: 759px) {
        margin: 0 16px 0 auto;
    }

    @media (min-width: 760px) {
        position: relative;
    }
`;

const SearchResultWrapper = styled.div`
    position: absolute;
    z-index: 25;
    left: 0;

    @media (max-width: 400px) {
        margin: 10px 0 0 10px;
    }
`;

const GlobalSearchBoxWrapper = styled.div`
    .ant-input-affix-wrapper,
    input {
        background: #f4f4f4;
    }
`;

const SearchBar = () => {
    const [isSearching, setIsSearching] = useState(false);
    const [isShowingMobileSearchBox, setIsShowingMobileSearchBox] = useState(
        false,
    );

    const search = (
        <GlobalSearchBoxWrapper className="global-search">
            <SearchBox
                onChange={e => setIsSearching(e.target.value.length !== 0)}
                onFocus={e => setIsSearching(e.target.value.length !== 0)}
                placeholder="Search here"
                extraProps={{ tabIndex: 1, style: { width: "370px" } }}
            />
        </GlobalSearchBoxWrapper>
    );

    return (
        <SearchInstance>
            <Wrapper>
                <Configure filters="NOT is_archived:1" hitsPerPage="20" />
                <Media query="(min-width: 760px)">{search}</Media>
                <Media query="(max-width: 759px)">
                    <>
                        <DynamicIcon
                            legacyType={
                                isShowingMobileSearchBox ? "close" : "search"
                            }
                            className="hoverable icon-large"
                            onClick={() => {
                                setIsShowingMobileSearchBox(v => !v);
                                setIsSearching(false);
                            }}
                        />
                        {isShowingMobileSearchBox ? (
                            <div
                                style={{
                                    position: "fixed",
                                    top: "10px",
                                    left: "10px",
                                    width: "160px",
                                }}
                            >
                                {search}
                            </div>
                        ) : null}
                    </>
                </Media>

                {isSearching ? (
                    <SearchResultWrapper>
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                setIsSearching(false);
                                setIsShowingMobileSearchBox(false);
                            }}
                        >
                            <GlobalSearchResults
                                onClick={() => {
                                    setIsSearching(false);
                                    setIsShowingMobileSearchBox(false);
                                }}
                            />
                        </OutsideClickHandler>
                    </SearchResultWrapper>
                ) : null}
            </Wrapper>
        </SearchInstance>
    );
};

export default SearchBar;
