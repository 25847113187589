import React, { lazy, Suspense, useState } from "react";
import toCamelCase from "../../helpers/toCamelCase";
import { LoadingOutlined } from "@ant-design/icons";

const CompatIcon = lazy(() => import("@ant-design/compatible/es/icon"));

const DynamicIcon = ({ type, legacyType, ...props }) => {
    const [Module, setModule] = useState(null);
    const [fallback, setFallback] = useState(false);

    const iconType = type
        ? type
        : legacyType && toCamelCase(legacyType, true) + "Outlined";

    if (iconType) {
        import("@ant-design/icons").then(icons => {
            const icon = icons[iconType];
            if (icon) {
                setModule(icon);
            } else {
                setFallback(true);
            }
        });
    }

    if (fallback) {
        return (
            <Suspense loading={<LoadingOutlined />}>
                <CompatIcon type={type} {...props} />;
            </Suspense>
        );
    }

    return Module ? <Module {...props} /> : null;
};

export default DynamicIcon;
