interface AnyObject {
    [key: string]: any
}


export function getWithArray(object: AnyObject, pathArray: string[], defaultValue: any = undefined) {
    if (typeof object === "undefined" || object === null) {
        return defaultValue;
    }

    let value = object[pathArray[0]];
    for (let i = 1; i < pathArray.length; i++) {
        value = getSingleValue(value, pathArray[i]);

        if (value === undefined) {
            return defaultValue;
        }
    }
    return value;
}

function getSingleValue(object: AnyObject, key: string) {
    if (
        typeof object === "undefined" ||
        object === null ||
        typeof object[key] === "undefined"
    ) {
        return undefined;
    }
    return object[key];
}

export default function get(object: AnyObject, path: string, defaultValue: any = undefined) {
    if (typeof object === "undefined" || object === null) {
        return defaultValue;
    }

    let parts = path.split(".");
    let value = object[parts[0]];
    for (let i = 1; i < parts.length; i++) {
        value = getSingleValue(value, parts[i]);

        if (value === undefined) {
            return defaultValue;
        }
    }
    return value;
}
