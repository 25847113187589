import React from "react";
import Avatar from "../../components/Avatar";
import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, Menu as AntdMenu } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userMenuItems } from "./menuItems";
import { connect } from "react-redux";
import { logout } from "../../api";
import { setIsLoggedIn as setIsLoggedInAction } from "../../actions";
import getConfig from "../../instance-config";

const DropdownIconWrapper = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

const menuIconStyle = {
    verticalAlign: "-0.225em",
    marginLeft: "10px",
};

const UserMenu = ({ currentUser, setIsLoggedIn, resetState }) => {
    return (
        <Dropdown
            className="user-menu"
            overlay={
                <AntdMenu>
                    {userMenuItems.map(item => (
                        <AntdMenu.Item key={item.key} className={item.key}>
                            <Link className="hide-link-styles" to={item.path}>
                                {item.text}
                            </Link>
                        </AntdMenu.Item>
                    ))}
                    {!getConfig().sso_enabled ? (
                        <AntdMenu.Item key="logout">
                            <span
                                className="hoverable logout"
                                onClick={() => {
                                    logout()
                                        .then(response => {
                                            setIsLoggedIn(false);
                                            resetState();
                                        })
                                        .catch(error => console.log(error));
                                }}
                            >
                                Logout
                            </span>
                        </AntdMenu.Item>
                    ) : null}
                </AntdMenu>
            }
            trigger={["click"]}
        >
            <DropdownIconWrapper>
                {currentUser ? (
                    <div>
                        <Avatar
                            size="default"
                            user={currentUser}
                            tooltip={false}
                        />
                        <CaretDownOutlined style={menuIconStyle} />
                    </div>
                ) : null}
            </DropdownIconWrapper>
        </Dropdown>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.appState.currentUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIsLoggedIn: (...args) => dispatch(setIsLoggedInAction(...args)),
        resetState: () => dispatch({ type: "USER_LOGOUT" }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
