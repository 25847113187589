import { hasVersionMismatch } from "../actions";

let persistentSettings = {};
try {
    persistentSettings =
        JSON.parse(localStorage.getItem("persistentSettings")) || {};
} catch (e) {}

export default function appState(
    appState = {
        requests: {},
        filters: {
            challenge: {
                "filter[workflows.entity.is_archived]": 0,
            },
            competition: {
                "filter[workflows.entity.is_archived]": 0,
            },
        },
        filterLabels: {},
        draftFilters: {
            challenge: {
                archived: {
                    query: {
                        "filter[workflows.entity.is_archived]": 0,
                    },
                },
            },
            competition: {
                archived: {
                    query: {
                        "filter[workflows.entity.is_archived]": 0,
                    },
                },
            },
        },
        clientNeedsRefresh: false,
        persistentSettings: persistentSettings,
    },
    action,
) {
    // An edge case that checks whether we've had a version mismatch
    // between request headers.
    if (hasVersionMismatch()) {
        appState = {
            ...appState,
            clientNeedsRefresh: true,
        };
    }

    switch (action.type) {
        case "API_REQUEST":
            return {
                ...appState,
                requests: {
                    ...appState.requests,
                    [action.key]: {
                        isLoading: true,
                        isFailed: false,
                    },
                },
            };

        case "API_SUCCESS":
            return {
                ...appState,
                requests: {
                    ...appState.requests,
                    [action.key]: {
                        isLoading: false,
                        isFailed: false,
                    },
                },
            };

        case "API_FAILURE":
            return {
                ...appState,
                requests: {
                    ...appState.requests,
                    [action.key]: {
                        isLoading: false,
                        isFailed: true,
                        message: action.payload,
                    },
                },
            };

        case "CURRENT_USER":
            return {
                ...appState,
                currentUser: action.user,
            };

        case "IS_LOGGED_IN":
            return {
                ...appState,
                isLoggedIn: action.result,
            };

        case "UPDATE_FILTERS":
            return {
                ...appState,
                filters: {
                    [action.workflowType]: {
                        ...action.filters,
                    },
                },
                filterLabels: {
                    [action.workflowType]: action.filterLabels,
                },
                draftFilters: {
                    [action.workflowType]: action.draftFilters,
                },
            };

        case "UPDATE_CHALLENGE_SORT":
            return {
                ...appState,
                challengeSort: {
                    ...action.sort,
                },
            };

        case "WORKFLOW_CHANGED_VIEW":
            return {
                ...appState,
                persistentSettings: {
                    ...appState.persistentSettings,
                    defaultViews: {
                        ...appState.persistentSettings.defaultViews,
                        [action.workflowTypeId]: action.view,
                    },
                },
            };

        default:
            return appState;
    }
}
