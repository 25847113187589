import React from "react";
import { Table as AntTable } from "antd";
import styled from "styled-components";

const StyledTable = styled(AntTable)`
    && {
        th {
            background: none;
            color: ${props => props.theme.greyBlack};
            font-size: 12px;
            font-weight: 500;
        }
        tr:hover,
        tr:hover td {
            background: none !important;
        }
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
            word-break: normal;

            @media (max-width: 600px) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .priority-low {
            @media (max-width: 900px) {
                display: none;
            }
        }

        .priority-medium {
            @media (max-width: 600px) {
                display: none;
            }
        }
    }
`;

export const TableCellName = styled.div`
    color: ${props => props.theme.greyBlack};
    font-size: 16px;
    font-weight: 500;

    a {
        color: ${props => props.theme.blueGrey};
        font-size: 16px;
        font-weight: 500;

        &:hover {
            color: ${props => props.theme.greyBlack};
        }
    }
`;
export const TableCellText = styled.div`
    font-size: 13px;
    color: ${props => props.theme.greyBlack};
`;

export default ({ pagination, ...props }) => {
    const defaultPagination = {
        defaultPageSize: 25,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };

    let paginationProp = defaultPagination;
    if (pagination === false || pagination) {
        paginationProp = pagination;
    }
    if (typeof pagination === "object") {
        paginationProp = {
            ...defaultPagination,
            ...pagination,
        };
    }
    return <StyledTable pagination={paginationProp} {...props} />;
};
