import React from "react";
import styled from "styled-components";

const SpacerStandard = styled.div`
    margin-top: ${props =>
        props.spacerDirection !== "bottom" ? "32px" : "0px"};
    margin-bottom: ${props =>
        props.spacerDirection !== "top" ? "32px" : "0px"};
    margin-left: ${props => (props.horizontal ? "32px" : "0px")};
    margin-right: ${props => (props.horizontal ? "32px" : "0px")};
`;

const SpacerSmall = styled.div`
    margin-top: ${props =>
        props.spacerDirection !== "bottom" ? "16px" : "0px"};
    margin-bottom: ${props =>
        props.spacerDirection !== "top" ? "16px" : "0px"};
    margin-left: ${props => (props.horizontal ? "16px" : "0px")};
    margin-right: ${props => (props.horizontal ? "16px" : "0px")};
`;

const SpacerTiny = styled.div`
    margin-top: ${props =>
        props.spacerDirection !== "bottom" ? "8px" : "0px"};
    margin-bottom: ${props =>
        props.spacerDirection !== "top" ? "8px" : "0px"};
    margin-left: ${props => (props.horizontal ? "8px" : "0px")};
    margin-right: ${props => (props.horizontal ? "8px" : "0px")};
`;

const Spacer = ({
    children,
    size = "normal",
    direction = "",
    horizontal = false,
    ...props
}) => {
    if (size === "tiny") {
        return (
            <SpacerTiny
                spacerDirection={direction}
                horizontal={horizontal}
                {...props}
            >
                {children}
            </SpacerTiny>
        );
    }
    if (size === "small") {
        return (
            <SpacerSmall
                spacerDirection={direction}
                horizontal={horizontal}
                {...props}
            >
                {children}
            </SpacerSmall>
        );
    }
    return (
        <SpacerStandard
            spacerDirection={direction}
            horizontal={horizontal}
            {...props}
        >
            {children}
        </SpacerStandard>
    );
};

export default Spacer;
