import { hasPermission } from "./HasPermission";

export default function isUserCollaborator(user, taskWorkflow) {
    if (
        typeof taskWorkflow.relationships === "undefined" ||
        typeof taskWorkflow.relationships.collaborators === "undefined"
    ) {
        return false;
    }

    let found = false;
    taskWorkflow.relationships.collaborators.data.forEach(collaborator => {
        if (collaborator.id === user.id) {
            found = true;
        }
    });
    return found;
}

export function isUserCollaboratorOrHasPermission(
    user,
    taskWorkflow,
    allPermissions,
    permission,
) {
    return (
        isUserCollaborator(user, taskWorkflow) ||
        hasPermission(allPermissions, permission)
    );
}
