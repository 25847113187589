export default function (name) {
    if (typeof name === "string") {
        let initials = name.match(/\b\w/g) || [];
        return (
            (initials.shift() || "") + (initials.pop() || "")
        ).toUpperCase();
    } else {
        return null;
    }
}
