import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import { Drawer, Menu as AntdMenu } from "antd";
import styled from "styled-components";
import Avatar from "../../components/Avatar";
import { mainMenuItems, userMenuItems } from "./menuItems";
import { connect } from "react-redux";
import { logout } from "../../api";
import adminMenuItems from "./adminMenuItems";
import { hasPermission } from "../../helpers/HasPermission";
import { getUserPermissions } from "../../selectors";
import { setIsLoggedIn as setIsLoggedInAction } from "../../actions";
import getConfig from "../../instance-config";

const SubMenu = AntdMenu.SubMenu;

const MenuVerticalStyled = styled(AntdMenu)`
    border-right: none !important;
`;

const MenuToggle = styled.span`
    margin-left: auto;
    margin-right: 20px;

    &:hover {
        cursor: pointer;
    }
`;

const MobileMenu = ({
    activeMenuItem,
    wrapperStyle,
    currentUser,
    currentUserPermissions,
    setIsLoggedIn,
    resetState,
}) => {
    const [isDrawerOpen, setDrawerStatus] = useState(false);

    return (
        <div style={wrapperStyle}>
            <MenuToggle onClick={() => setDrawerStatus(!isDrawerOpen)}>
                <svg
                    height="32px"
                    id="Layer_1"
                    version="1.1"
                    viewBox="0 0 32 32"
                    width="32px"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
                </svg>
            </MenuToggle>
            <Drawer
                title={
                    currentUser ? (
                        <span>
                            <Avatar size="default" user={currentUser} />
                            <span style={{ marginLeft: "16px" }}>
                                {currentUser.attributes.display_name}
                            </span>
                        </span>
                    ) : null
                }
                placement="left"
                closable={false}
                onClose={() => setDrawerStatus(!isDrawerOpen)}
                visible={isDrawerOpen}
            >
                <MenuVerticalStyled
                    theme="light"
                    mode="inline"
                    style={{ lineHeight: "64px", background: "none" }}
                    defaultSelectedKeys={[activeMenuItem]}
                    onClick={() => setDrawerStatus(false)}
                >
                    {mainMenuItems.map(item => (
                        <AntdMenu.Item key={item.key}>
                            <Link className="hide-link-styles" to={item.path}>
                                {item.text}
                            </Link>
                        </AntdMenu.Item>
                    ))}

                    <AntdMenu.Divider />
                    {userMenuItems.map(item => (
                        <AntdMenu.Item key={item.key}>
                            <Link className="hide-link-styles" to={item.path}>
                                {item.text}
                            </Link>
                        </AntdMenu.Item>
                    ))}
                    {!getConfig().sso_enabled ? (
                        <AntdMenu.Item key="logout">
                            <span
                                className="hoverable"
                                onClick={() => {
                                    logout()
                                        .then(response => {
                                            setIsLoggedIn(false);
                                            resetState();
                                        })
                                        .catch(error => console.log(error));
                                }}
                            >
                                Logout
                            </span>
                        </AntdMenu.Item>
                    ) : null}

                    {hasPermission(
                        currentUserPermissions,
                        "publish competition",
                    ) ? (
                        <AntdMenu.Divider />
                    ) : null}
                    {hasPermission(
                        currentUserPermissions,
                        "publish competition",
                    ) ? (
                        <SubMenu
                            key="admin"
                            title={
                                <span>
                                    <SettingOutlined />
                                    <span>Admin</span>
                                </span>
                            }
                        >
                            {adminMenuItems.map(item => (
                                <AntdMenu.Item key={item.key}>
                                    <Link
                                        className="hide-link-styles"
                                        to={item.path}
                                    >
                                        {item.text}
                                    </Link>
                                </AntdMenu.Item>
                            ))}
                        </SubMenu>
                    ) : null}
                </MenuVerticalStyled>
            </Drawer>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.appState.currentUser,
        currentUserPermissions: getUserPermissions(
            state,
            state.appState.currentUser,
        ),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIsLoggedIn: (...args) => dispatch(setIsLoggedInAction(...args)),
        resetState: () => dispatch({ type: "USER_LOGOUT" }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
