export default function draftTasks(data = {}, action) {
    switch (action.type) {
        case "UPDATE_DRAFT":
            let newData = { ...data };
            if (!newData[action.parentId]) {
                newData[action.parentId] = [];
            }

            newData[action.parentId] = newData[action.parentId].filter(
                task => task.id !== action.taskId,
            );

            newData[action.parentId].push({
                id: action.taskId,
                parentId: action.parentId,
                data: action.data,
            });
            return newData;

        default:
            return data;
    }
}
