import React, { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { withRouter } from "react-router-dom";
import { InstantSearch } from "react-instantsearch-dom";
import getConfig from "../../instance-config";
import qs from "qs";

const appId = "9WMPXP5O32";
const apiKey = "cd9adc75851d4fdf932e0572933aff5b";
const searchClient = algoliasearch(appId, apiKey);

const SearchInstance = ({ children }) => {
    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={getConfig().algoliaIndexName}
        >
            {children}
        </InstantSearch>
    );
};

const DEBOUNCE_TIME = 400;
const createURL = state => `?${qs.stringify(state)}`;
const searchStateToUrl = (location, searchState) => {
    return searchState
        ? `${location.pathname}${createURL(searchState)}`
        : location.pathname;
};
const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

const SearchInstanceUrlSynced = withRouter(
    ({ children, history, location }) => {
        const [searchState, setSearchState] = useState(
            urlToSearchState(location),
        );
        const [debouncedSetState, setDebouncedSetState] = useState(null);

        const onSearchStateChange = updatedSearchState => {
            clearTimeout(debouncedSetState);

            setDebouncedSetState(
                setTimeout(() => {
                    history.push(
                        searchStateToUrl(location, updatedSearchState),
                        updatedSearchState,
                    );
                }, DEBOUNCE_TIME),
            );

            setSearchState(updatedSearchState);
        };

        return (
            <InstantSearch
                searchClient={searchClient}
                indexName={getConfig().algoliaIndexName}
                searchState={searchState}
                onSearchStateChange={onSearchStateChange}
                createURL={createURL}
            >
                {children}
            </InstantSearch>
        );
    },
);

export default SearchInstance;
export { searchClient, SearchInstanceUrlSynced };
