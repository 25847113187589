import styled from "styled-components";

const Box = styled.div`
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 32px;
    overflow-wrap: break-word;
    position: relative;
    height: 100%;

    @media (max-width: 600px) {
        padding: 16px;
    }
`;

export const AutoHeightBox = styled(Box)`
    height: auto;
`;
export default Box;
