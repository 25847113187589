import React, { Component } from "react";
import GenericError from "./GenericError";

class ErrorBoundary extends Component {
    static defaultProps = {
        silent: false,
    };

    constructor() {
        super();
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        console.log(error);
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            if (this.props.silent) {
                return <div></div>;
            }

            return (
                <GenericError
                    title="Something went wrong"
                    message="This incident has been reported and will be investigated. If it persists please let us know."
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
