import { get } from "../../helpers";

export default function createLink(playbookId, action = "build") {
    if (playbookId === undefined || playbookId === null) {
        return "";
    }
    return `/challenge/${playbookId}/${action}`;
}

export function createLinkFromPlaybook(playbook, action = "build") {
    return createLink(get(playbook, "id"), action);
}
