import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import setPageTitle from "../hoc/setPageTitle";

const Wrapper = styled.div`
    background: #f5f5f5;
    height: 100%;
`;

const Middle = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
`;

const LoadingPage = ({ children }) => {
    return (
        <Wrapper>
            <Middle>
                <Spin>{children}</Spin>
            </Middle>
        </Wrapper>
    );
};

export default setPageTitle(LoadingPage, "Loading...");
