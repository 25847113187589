import React from "react";
import { getCurrentUser, getUserPermissions } from "../selectors";
import { compose } from "redux";
import { connect } from "react-redux";

const WithPermissionsComponent = WrappedComponent => {
    return props => {
        return <WrappedComponent {...props} />;
    };
};

const mapStateToProps = state => {
    let currentUser = getCurrentUser(state);
    return {
        currentUser: currentUser,
        permissions: getUserPermissions(state, currentUser),
    };
};

const withPermissions = compose(
    connect(mapStateToProps),
    WithPermissionsComponent,
);

export default withPermissions;
