// This list is also maintained in drupal/app/profiles/unearthed_industry/modules/custom/unearthed_portal_proxy/src/ChallengeClassification.php.
export const dataScienceRemoteCompetitionIds = [
    "31228868-9e0e-41d6-a5f5-154e6c0f52c4",
    "7a398473-5eba-48cc-bbd8-7e4a64c2f139",
    "c2be70b7-ad21-4dbc-badc-745502b94cf9",
    "76248e99-a8d4-4f5c-99e7-4c6328ef4ac6",
    "04d894ae-5585-4662-8c0e-f7dd184682da",
    "240d231f-411d-4195-a758-ff2e8709d99c",
    "f65e9fcf-b38e-470a-ab4d-7218c6589d24",
    "db4ac2a7-e3b0-4069-bcd8-c304a66b72f6",
    "729c21b3-b472-442f-b5ae-c96885362154",
    "bd687899-bf25-4327-8255-11d842486046",
    "80fb7693-1d0d-4fe1-9792-9b8413e4bdce",
    "055b55a2-9be2-46bd-8d46-4b670c0c89a9",
    "669b7fe7-26ba-4844-9db5-8a42d8fc1eae",
    "fdee27a4-ae90-47a6-bd25-596c1714872d",
    "5c39faa0-8ceb-4195-801b-330f1a1c014d",
    "abc7787e-2a27-48c9-b4ba-9e195e22b2e6",
    "75b1b39c-acc9-49a3-aae2-779e7df1aab6",
    "db6abd99-e2d3-4bf9-81d0-cc1c3a43894a",
    "4d5621e8-ab3d-4acb-9cc1-442e20240c42",
    "5271eecc-fc72-4f90-87ef-fd116c3e1587",
    "90e09394-ef70-47f0-a93c-424bfa9abbf6",
    "9e468b0c-083a-4eac-8763-a8776c882dd2",
    "0a4e49ce-dbcc-4c70-b0a9-5816ff09fdbb",
    "8c063566-3672-499e-8190-ffb562e1ae22",
    "23281334-abbe-4c2b-b28a-59ee605cdcb6",
    "f35f8131-356f-4231-8af0-f26c50d522df",
    "6683c3f7-19dc-401f-9312-87ecce87cff4",
    "a4390da5-4542-427d-9193-81d49a0ee351",
    "be70b05a-51fd-462f-9b14-3e27871cf37e",
    "d19f8036-8128-4ee9-82f5-3a25f32cd2ea",
    "98e48c47-bc02-403f-9f96-162431da6eb6",
    "8a225bc0-0f20-44c5-80f3-730fe9729673",
    "4e24cf9a-1ede-451a-a18b-b59065944891",
    "72815b3d-e937-43db-b674-2f9110ed57ae",
    "6a6dd107-dbd5-4620-ba08-ac14ea7bc01e",
    "0e5ddd74-1c29-432e-8f2e-7cde8224b627",
    "527b1a73-1523-45fe-be67-0bd321cea77c",
    "0bc422bb-85ea-49ac-bee8-913d09e3da3e",
    "04d41bc7-5663-41ea-8549-7e3fb88dcef4",
    "e84f5b81-1dfb-460c-aaed-9f30a361023a",
    "d2556e29-e6b4-4984-89ac-6c952b524456",
    "57a395d2-631f-4474-a7bb-6ba1c79c226e",
    "531ca6f9-dfe4-49aa-b1b7-a2472ef6449f",
    "da9a2a88-775c-434b-ba0f-e13d3ff6aab2",
    "e81a46f6-216f-4ea9-bebe-774db98a2cd3",
    "d3bdc836-7425-425a-a693-ebc9408603b9",
    "4d9fd368-f12d-4d06-962d-70e50360df73",
    "124e419c-047b-45b9-b6ed-b35499e96f95",
    "2c1dbc79-1f2f-4e94-a2c4-77703196192a",
    "a759548e-6be5-4199-b3ec-7c0fdb459b35",
];
