import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import FullWidthContentBlock from "../components/FullWidthContentBlock";

const Wrapper = styled.div`
    display: flex;

    @media (max-width: 759px) {
        flex-direction: column;
    }
`;

const Left = styled.div`
    width: 100%;
`;

const Right = styled.div`
    flex-shrink: 0;
    width: 280px;
    margin-left: 8px;

    @media (max-width: 759px) {
        margin: 0 auto;
        width: 100%;
    }
`;

interface Props {
    left?: JSX.Element | null;
    right?: JSX.Element | null;
    isLoading?: boolean;
}

const TwoCol7525: React.FC<Props> = ({
    left,
    right,
    children,
    isLoading = false,
}) => {
    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <FullWidthContentBlock>
                <Wrapper>
                    <Left>{left}</Left>
                    <Right>{right}</Right>
                    {children}
                </Wrapper>
            </FullWidthContentBlock>
        </Spin>
    );
};

export default TwoCol7525;
