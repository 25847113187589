import { makeGetApiCall, makePatchApiCall, makePostApiCall } from "./api";
import encodeObjectToQueryString from "../helpers/encodeObjectToQueryString";
import { IMAGE_LARGE_CONSUMER_ID } from "../consumers";
import { loadTaskWorkflow } from "./workflows";

export function createChallenge(data) {
    return function (dispatch, getState) {
        return makePostApiCall(
            dispatch,
            "CREATE_CHALLENGE",
            "/jsonapi/task_workflow/challenge",
            data,
        );
    };
}

export function updateChallenge(challenge) {
    return function (dispatch, getState) {
        let requestData = {
            data: challenge,
        };

        // Protected fields are removed from all requests.
        ["drupal_internal__id", "changed"].forEach(
            field => delete requestData.data.attributes[field],
        );

        return makePatchApiCall(
            dispatch,
            "UPDATE_CHALLENGE",
            `/jsonapi/task_workflow/challenge/${challenge.id}`,
            requestData,
        );
    };
}

export function loadChallenges(
    filters = {},
    sort = {
        "sort[sort-created][path]": "created",
        "sort[sort-created][direction]": "DESC",
    },
) {
    return function (dispatch, getState) {
        let requestKey = getRequestKey("challenges");

        const query = {
            include:
                "uid,task_template,collaborators,uid.field_picture,collaborators.field_picture",
            consumerId: IMAGE_LARGE_CONSUMER_ID,
            "filter[is_archived]": 0,
            ...filters,
            ...sort,
        };

        let queryString = encodeObjectToQueryString(query);
        return makeGetApiCall(
            dispatch,
            requestKey,
            "/jsonapi/task_workflow/challenge?" + queryString,
        );
    };
}

export function loadChallenge(challengeId) {
    return loadTaskWorkflow("challenge", challengeId);
}

export function getRequestKey(type) {
    return type.toUpperCase();
}

export function updateFilters(
    workflowType,
    filters,
    filterLabels,
    draftFilters,
) {
    return {
        type: "UPDATE_FILTERS",
        workflowType: workflowType,
        filters: filters,
        filterLabels: filterLabels,
        draftFilters: draftFilters,
    };
}

export function updateChallengeSort(sort) {
    return {
        type: "UPDATE_CHALLENGE_SORT",
        sort: sort,
    };
}
