import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import styled from "styled-components";

const StyledInput = styled(Input)`
    max-width: 100%;
    input {
        color: rgba(0, 0, 0, 0.54);
    }
`;

const SearchBox = connectSearchBox(
    ({
        onChange = () => {},
        onFocus = () => {},
        placeholder,
        currentRefinement,
        isSearchStalled,
        refine,
        extraProps = {},
    }) => (
        <StyledInput
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,0.55)" }} />}
            onChange={event => {
                refine(event.currentTarget.value);
                onChange(event);
            }}
            onFocus={event => {
                refine(event.currentTarget.value);
                onFocus(event);
            }}
            placeholder={placeholder}
            value={currentRefinement}
            {...extraProps}
        />
    ),
);

export default SearchBox;
