import { Input as AntInput } from "antd";
import styled from "styled-components";

const TextArea = styled(AntInput.TextArea)`
    && {
        min-height: 88px;
    }
`;

TextArea.displayName = "TextArea";

export default TextArea;
