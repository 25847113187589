const toCamelCase = (input, capitaliseFirstLetter = false) => {
    if (typeof input !== "string") return "";
    let out = input?.toLowerCase()?.replace(/-(.)/g, function (match, group1) {
        return group1?.toUpperCase();
    });
    if (capitaliseFirstLetter) {
        out = out?.replace(/^[a-zA-Z]/g, function (match, group1) {
            return match?.toUpperCase();
        });
    }
    return out ? out : "";
};
export default toCamelCase;
