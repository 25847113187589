import { Select as AntSelect } from "antd";
import styled from "styled-components";

const Select = styled(AntSelect)`
    && {
        .ant-select-selection--single {
            height: 38px;
            line-height: 36px;
        }
        .ant-select-selection__rendered {
            line-height: 36px;
        }
    }
`;

export default Select;
