const mergeEntityAttributes = (entity, newAttributes) => {
    try {
        if (typeof entity !== "object" || typeof newAttributes !== "object")
            return null;

        let updatedEntity = JSON.parse(JSON.stringify(entity));

        updatedEntity.attributes = {
            ...updatedEntity.attributes,
            ...newAttributes,
        };
        return updatedEntity;
    } catch (error) {
        return null;
    }
};

export default mergeEntityAttributes;
