import React from "react";
import PageNotFound404 from "../pages/PageNotFound404";
import LoginPage from "../pages/LoginPage";

export default function requireAuth(
    Component,
    routes,
    hasCorrectRoles,
    isLoggedIn,
    isAnonymous,
    checkLogin,
    location,
) {
    if (!isLoggedIn) {
        // If they are not logged in but the page supports anonymous users then
        // render the page.
        if (isAnonymous) {
            return (
                <Component
                    routes={routes}
                    hasCorrectRoles={hasCorrectRoles}
                    isLoggedIn={isLoggedIn}
                    isAnonymous={isAnonymous}
                    checkLogin={checkLogin}
                    location={location}
                />
            );
        }

        // Otherwise they get the login page.
        return <LoginPage checkLogin={checkLogin} />;
    }

    return hasCorrectRoles ? (
        <Component
            routes={routes}
            hasCorrectRoles={hasCorrectRoles}
            isLoggedIn={isLoggedIn}
            isAnonymous={isAnonymous}
            checkLogin={checkLogin}
            location={location}
        />
    ) : (
        <PageNotFound404 />
    );
}
