import getConfig from "../../instance-config";
import { dataScienceRemoteCompetitionIds } from "./dataScienceRemoteCompetitionIds";

export default function getBaseUrl(competitionId) {
    return isDataScienceCompetition(competitionId)
        ? getConfig().dataScienceBaseUrl
        : getConfig().innovatorPortalBaseUrl;
}

export function getBaseHost(competitionId) {
    return isDataScienceCompetition(competitionId)
        ? getConfig().dataScienceBaseHost
        : getConfig().innovatorPortalBaseHost;
}

export function isDataScienceCompetition(competitionId) {
    if (!competitionId) return false;
    const index = dataScienceRemoteCompetitionIds.indexOf(competitionId);
    return index !== -1;
}
