import React from "react";
import { connectHits, Highlight } from "react-instantsearch-dom";
import styled from "styled-components";
import PlaybookLink from "../Playbook/PlaybookLink";
import { hasPermission } from "../../helpers/HasPermission";
import withPermissions from "../../hoc/withPermissions";
import DynamicIcon from "../Ant/DynamicIcon";

const HitsWrapper = styled.div`
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    width: 400px;
    max-width: 100%;

    @media (max-width: 400px) {
        width: 300px;
    }

    h4 {
        margin: 16px;
    }

    .ais-Highlight-highlighted {
        font-weight: 600;
        font-style: none;
    }
`;

const HitsList = styled.ol`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const HitsListItem = styled.li`
    > a {
        color: inherit;
        padding: 16px 16px;
        display: block;
        border-bottom: 1px solid #eee;

        &:focus,
        &:hover {
            background: #f5f6f5;
        }
    }
    &:last-child {
        > a {
            border: none;
        }
    }
`;

const HitsListStyled = ({ hits, icon, onClick }) => (
    <HitsList>
        {hits.map(hit => {
            const collaborators = Array.isArray(hit.collaborator_uuid)
                ? hit.collaborator_uuid
                : [hit.collaborator_uuid];
            return (
                <HitsListItem key={hit.uuid} onClick={onClick}>
                    <PlaybookLink
                        extraProps={{
                            tabIndex: 2,
                        }}
                        playbook={{
                            id: hit.uuid,
                            type: "task_workflow--" + hit.type,
                            attributes: {
                                playbook_status: hit.playbook_status,
                                playbook_id: hit.playbook_id,
                            },
                            relationships: {
                                collaborators: {
                                    data: collaborators.map(c => {
                                        return {
                                            type: "user--user",
                                            id: c,
                                        };
                                    }),
                                },
                            },
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <DynamicIcon
                                legacyType={icon}
                                className="icon-large"
                                style={{ marginRight: "16px" }}
                            />
                            <Highlight hit={hit} attribute="title" />
                        </div>
                    </PlaybookLink>
                </HitsListItem>
            );
        })}
    </HitsList>
);

const GlobalSearchResults = ({
    hits,
    HitComponent,
    onClick,
    currentUser,
    permissions,
}) => {
    if (hits.length === 0) {
        return null;
    }
    let filteredHits = hits;

    // If you can view all workflows, then there is no filtering required.
    // otherwise, we filter out anything you are not a collaborator on.
    if (!hasPermission(permissions, "access task_workflow overview")) {
        console.log("filtering results based on permissions");
        filteredHits = filteredHits.filter(hit => {
            if (Array.isArray(hit.collaborator_uuid)) {
                return hit.collaborator_uuid.includes(currentUser.id);
            }
            return hit.collaborator_uuid === currentUser.id;
        });
    }

    if (filteredHits.length === 0) {
        return null;
    }

    const competitionHits = filteredHits.filter(h => h.type === "competition");
    const challengeHits = filteredHits.filter(h => h.type === "challenge");

    return (
        <HitsWrapper>
            {challengeHits.length !== 0 ? (
                <>
                    <h4>Challenges</h4>
                    <HitsListStyled
                        hits={challengeHits}
                        icon="bulb"
                        onClick={onClick}
                    />
                </>
            ) : null}

            {competitionHits.length !== 0 ? (
                <>
                    <br />
                    <h4>Competitions</h4>
                    <HitsListStyled
                        hits={competitionHits}
                        icon="flag"
                        onClick={onClick}
                    />
                </>
            ) : null}
        </HitsWrapper>
    );
};

const GlobalSearchResultsConnected = connectHits(GlobalSearchResults);

export default withPermissions(GlobalSearchResultsConnected);
export { GlobalSearchResults as _GlobalSearchResults };
