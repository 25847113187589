import React, { useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { createLinkFromPlaybook } from "../TaskWorkflow/createLink";
import { get, isUserCollaboratorOrHasPermission } from "../../helpers";
import withCurrentUser from "../../hoc/withCurrentUser";
import md5 from "md5";
import withPermissions from "../../hoc/withPermissions";
import usePlaybook from "../../api/usePlaybook";

const PlaybookLink = ({
    currentUser,
    playbook,
    children,
    permissions,
    className,
    style = {},
    extraProps = {},
}) => {
    const getAction = useCallback(() => {
        let status = get(playbook, "attributes.playbook_status", "");

        // Shows live / results even if they don't have persmissions
        if (status === "published" || status === "ready_to_publish") {
            return "live";
        }

        if (status === "complete") {
            return "results";
        }

        if (
            currentUser &&
            playbook &&
            permissions &&
            !isUserCollaboratorOrHasPermission(
                currentUser,
                playbook,
                permissions,
                "administer playbook",
            )
        ) {
            // If don't have permissions
            return "preview";
        }

        if (!status || status === "draft") {
            // If something has gone wrong, just default to the opportunity page.
            return "opportunity";
        }

        return "build";
    }, [playbook, currentUser, permissions]);

    return (
        <Link
            to={createLinkFromPlaybook(playbook, getAction())}
            className={
                "playbook-tile-" +
                md5(get(playbook, "attributes.playbook_title", "") || "") +
                ` ${className}`
            }
            style={{ ...style }}
            {...extraProps}
        >
            {children}
        </Link>
    );
};

// Handles being passed both workflows and playbooks
export const ActivityLink = ({ workflow, children, ...props }) => {
    const [playbook, setPlaybook] = useState(null);
    const { playbook: workflowPlaybook } = usePlaybook(
        workflow?.attributes.playbook_id,
    );
    useEffect(() => {
        if (workflowPlaybook?.type === "playbook--playbook") {
            setPlaybook(workflowPlaybook);
        } else {
            // If usePlaybook didn't work then workflow is a playbook
            setPlaybook(workflow);
        }
    }, [workflowPlaybook, workflow]);

    // Prevents broken links flashing in
    if (playbook?.type === "playbook--playbook") {
        return (
            <PlaybookLink playbook={playbook} {...props}>
                {children}
            </PlaybookLink>
        );
    }

    return children;
};

export default withPermissions(withCurrentUser(PlaybookLink));
export { PlaybookLink as _PlaybookLink };
