import React from "react";
import axios, { CancelToken } from "axios";
import { withToken } from "../actions";

export class BackendApi {
    cacheMap = {};
    cancelMap = {};
    client = null;

    constructor(client) {
        this.client = client;
    }

    getClient() {
        return this.client();
    }

    clearCache() {
        this.cacheMap = {};
    }

    patch(url, data) {
        this.clearCache();
        return withToken(token => {
            return this.getClient().patch(url, JSON.stringify(data), {
                headers: {
                    "X-CSRF-Token": token,
                },
            });
        });
    }

    post(url, data) {
        this.clearCache();
        return withToken(token => {
            return this.getClient().post(url, JSON.stringify(data), {
                headers: {
                    "X-CSRF-Token": token,
                },
            });
        });
    }

    delete(url) {
        this.clearCache();
        return withToken(token => {
            return this.getClient().delete(url, {
                headers: {
                    "X-CSRF-Token": token,
                },
            });
        });
    }

    get(url) {
        if (this.cacheMap[url] === undefined) {
            const source = CancelToken.source();
            this.cancelMap[url] = source.cancel;

            this.cacheMap[url] = this.getClient()
                .get(url, { cancelToken: source.token })
                .then(response => {
                    this.cacheMap[url] = Promise.resolve({
                        data: response.data,
                    });
                    return this.cacheMap[url];
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        return Promise.reject(error);
                    }
                    // Silently ignore if we're just cancelling the request.
                });
        } else {
            // Cache hit.
        }

        return this.cacheMap[url];
    }

    cancel(url) {
        // @TODO, it's possible although not yet apparent in our app that 2 components
        // request the same data, the user navigates away which unmounts 1 component which
        // then requests to cancel the request, but the other component is still waiting for
        // the promise to resolve.
        if (typeof this.cancelMap[url] === "function") {
            this.cancelMap[url]();

            // Invalidate the cache map so that if another component requests the data they don't
            // keep getting the cancelled promise but a refresh request.
            delete this.cacheMap[url];
        }
    }
}

export const ApiContext = React.createContext(null);
export const InnovatorApiContext = React.createContext(null);
