import { get } from "./helpers";

export function getTask(state, taskId) {
    if (typeof state.jsonApi["task--task"] === "undefined") {
        return null;
    }

    return typeof state.jsonApi["task--task"][taskId] === "undefined"
        ? null
        : state.jsonApi["task--task"][taskId];
}

export function getDraftTasksData(state, taskWorkflowId) {
    if (typeof state.draftTasks[taskWorkflowId] === "undefined") {
        return [];
    }
    return state.draftTasks[taskWorkflowId];
}

export function getTaskWorkflowTasks(state, type, taskWorkflowId) {
    let taskWorkflow = getTaskWorkflow(state, type, taskWorkflowId);
    return taskWorkflow
        ? taskWorkflow.relationships.tasks.data
              .map(task => getTask(state, task.id))
              .filter(task => task)
              .filter(task => !task.attributes.is_archived)
        : [];
}

export function getTaskWorkflow(state, type, taskWorkflowId) {
    if (typeof state.jsonApi["task_workflow--" + type] === "undefined") {
        return null;
    }

    let taskWorkflow = state.jsonApi["task_workflow--" + type][taskWorkflowId];
    if (typeof taskWorkflow === "undefined") {
        return null;
    }

    return taskWorkflow;
}

export function getTaskWorkflows(state, type) {
    let key = "task_workflow--" + type;
    if (typeof state.jsonApi[key] === "undefined") {
        return [];
    }
    return Object.values(state.jsonApi[key]);
}

export function getActiveRequest(state, key) {
    if (
        typeof state.appState.requests === "undefined" ||
        typeof state.appState.requests[key] === "undefined"
    ) {
        return { isLoading: true };
    }
    return state.appState.requests[key];
}

export function getRelationship(state, relationshipName, entity) {
    if (!entity || !entity.relationships) {
        return null;
    }

    if (!entity.relationships[relationshipName]) {
        return null;
    }

    const { data } = entity.relationships[relationshipName];

    if (Array.isArray(data)) {
        // Needs tests, handles occurences when the related entities have not been loaded.
        return data
            .map(handle => {
                if (
                    typeof state.jsonApi[handle.type] === "undefined" ||
                    state.jsonApi[handle.type][handle.id] === "undefined"
                ) {
                    return null;
                }
                return state.jsonApi[handle.type][handle.id];
            })
            .filter(e => e !== null);
    }

    if (data === null || typeof state.jsonApi[data.type] === "undefined") {
        return null;
    }

    // It might be that the data hasn't been loaded yet so we return null.
    if (typeof state.jsonApi[data.type][data.id] === "undefined") {
        return null;
    }

    return state.jsonApi[data.type][data.id];
}

export function getUserPermissions(state, user) {
    let permissions = [];
    let roles = getRelationship(state, "roles", user);

    if (!roles) {
        return [];
    }

    roles.forEach(role => {
        permissions = permissions.concat(role.attributes.permissions);
    });

    return permissions;
}

export function getCurrentUser(state) {
    if (
        typeof state.appState === "undefined" ||
        typeof state.appState.currentUser === "undefined"
    ) {
        return null;
    }
    return state.appState.currentUser;
}

export function getUser(state, uid) {
    return get(state, `jsonApi.user--user.${uid}`) || null;
}

export function getMyTasks(state) {
    if (
        typeof state.jsonApi === "undefined" ||
        typeof state.jsonApi["task--task"] === "undefined"
    ) {
        return [];
    }

    let currentUser = getCurrentUser(state);
    if (currentUser === null) {
        return [];
    }

    return Object.values(state.jsonApi["task--task"]).filter(task => {
        // When the task does not have an owner.
        if (task.relationships.uid.data === null) {
            return false;
        }
        return task.relationships.uid.data.id === currentUser.id;
    });
}

export function getMyIncompleteTasks(state) {
    return getMyTasks(state).filter(task => !task.attributes.is_complete);
}

export function getMyTaskWorkflows(state, type) {
    let key = "task_workflow--" + type;
    if (typeof state.jsonApi[key] === "undefined") {
        return [];
    }

    let currentUser = getCurrentUser(state);
    if (currentUser === null) {
        return [];
    }

    return Object.values(state.jsonApi[key]).filter(taskWorkflow =>
        taskWorkflow.relationships.collaborators.data.filter(
            collaborator => collaborator.id === currentUser.id,
        ),
    );
}

export function getPlaybookWorkflows(state, playbookId, type = null) {
    if (
        typeof state.jsonApi["playbook--playbook"] !== "undefined" &&
        typeof state.jsonApi["playbook--playbook"][playbookId] !== "undefined"
    ) {
        if (type === null) {
            return getRelationship(
                state,
                "workflows",
                state.jsonApi["playbook--playbook"][playbookId],
            );
        }
        return getRelationship(
            state,
            "workflows",
            state.jsonApi["playbook--playbook"][playbookId],
        ).filter(workflow => workflow.type === type);
    }

    return [];
}

export function getPlaybook(state, playbookId) {
    if (typeof state.jsonApi["playbook--playbook"] !== "undefined") {
        return state.jsonApi["playbook--playbook"][playbookId] || null;
    }
    return null;
}

export function getTaskTemplates(state) {
    if (typeof state.jsonApi["task_template--task_template"] !== "undefined") {
        return state.jsonApi["task_template--task_template"];
    }
    return [];
}

export function getComments(state, commentTypeId) {
    if (typeof state.jsonApi["comment--" + commentTypeId] !== "undefined") {
        return state.jsonApi["comment--" + commentTypeId];
    }
    return [];
}

export function getTaskTemplate(state, id) {
    if (typeof state.jsonApi["task_template--task_template"] !== "undefined") {
        return state.jsonApi["task_template--task_template"][id] || null;
    }
    return null;
}

export function getOrganisation(state, id) {
    if (typeof state.jsonApi["organisation--organisation"] !== "undefined") {
        return state.jsonApi["organisation--organisation"][id] || null;
    }
    return null;
}
