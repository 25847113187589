import jwt_decode from "jwt-decode";
import axios from "axios";
import { getBaseUrl } from "../actions";
import { extractErrorMessage } from "../api";

export function clearCurrentToken() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("jwt_expiry");
}

export function saveJwtToken(token) {
    let decodedToken = jwt_decode(token);

    localStorage.setItem("jwt", token);
    localStorage.setItem("jwt_expiry", decodedToken.exp);
}

export function hasToken() {
    let expiry = localStorage.getItem("jwt_expiry");
    return expiry !== null;
}

export function isCurrentTokenExpired() {
    let expiry = localStorage.getItem("jwt_expiry");
    return expiry === null || expiry < Date.now() / 1000;
}

export function getCurrentToken() {
    return localStorage.getItem("jwt");
}

export function refreshToken() {
    return axios
        .get("/jwt/token", {
            baseURL: getBaseUrl(),
            withCredentials: true,
        })
        .then(tokenResponse => {
            try {
                saveJwtToken(tokenResponse.data.token);
                return Promise.resolve(tokenResponse.data.token);
            } catch (e) {
                return Promise.reject("Unable to access local storage");
            }
        })
        .catch(error => {
            console.log(error);
            if (
                typeof error.response === "object" &&
                error.response.status === 403
            ) {
                error = "You have been logged out, please refresh the page";
            }
            return Promise.reject(extractErrorMessage(error));
        });
}
