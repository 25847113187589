import React, { CSSProperties } from "react";
import { ThunderboltOutlined } from "@ant-design/icons";
import { Button } from "antd";

const wrapperStyles: CSSProperties = {
    textAlign: "center",
};

const iconWrapperStyles: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "32px",
};

interface Props {
    title: string;
    message: string;
    hideRefresh?: boolean;
}

const GenericError: React.FC<Props> = ({
    title,
    message,
    hideRefresh = false,
    children,
}) => {
    return (
        <div style={wrapperStyles}>
            <div style={iconWrapperStyles}>
                <ThunderboltOutlined
                    style={{ fontSize: "48px", marginRight: "8px" }}
                />
                <h1 style={{ marginRight: "16px" }}>{title}</h1>
            </div>
            <p style={{ marginBottom: "32px" }}>{message}</p>
            {children}
            {hideRefresh ? null : (
                <Button type="primary" onClick={() => window.location.reload()}>
                    Refresh Page
                </Button>
            )}
        </div>
    );
};

export default GenericError;
