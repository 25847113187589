import React from "react";
import { GenericError } from "../components/Common";
import { Button, Layout } from "antd";
import styled from "styled-components";
import setPageTitle from "../hoc/setPageTitle";
import { Link } from "react-router-dom";

const { Content } = Layout;

const ContentStyled = styled(Content)`
    margin: 16px auto 16px auto;
    width: 100%;
    max-width: 1640px;
    padding: 16px;

    @media (max-width: 600px) {
        padding: 8px;
    }
`;

interface Props {
    returnPath?: {
        name: string;
        url: string;
    };
    title?: string;
    message?: string;
}

const PageNotFound404: React.FC<Props> = ({ returnPath, title, message }) => {
    return (
        <Layout style={{ background: "#F5F5F5" }}>
            <ContentStyled>
                <GenericError
                    title={title || "Page Not Found"}
                    message={
                        message ||
                        "The page you are looking for does not exist or you do not have access."
                    }
                    hideRefresh={true}
                >
                    {returnPath ? (
                        <Link to={returnPath.url}>
                            <Button type="primary">
                                Return to {returnPath.name}
                            </Button>
                        </Link>
                    ) : (
                        <Link to="/">
                            <Button type="primary">Take me Home</Button>
                        </Link>
                    )}
                </GenericError>
            </ContentStyled>
        </Layout>
    );
};

export default setPageTitle(PageNotFound404, "Page Not Found");
