import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;

    > button {
        margin-left: 8px;

        &:first-child {
            margin-left: 0;
        }
    }
`;

const FormActions = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

export default FormActions;
