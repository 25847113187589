import React, { useState, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "antd";

import { message } from "antd";
import FormActions from "../components/Form/FormActions";
import { Button, Input } from "../components/Ant";
import { login, extractErrorMessage } from "../api";

const LoginForm = ({ history, checkLogin, match, isLoggedIn }) => {
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = useCallback(
        result => {
            setIsLoading(true);
            login(result.email, result.password)
                .then(response => {
                    setIsLoading(false);

                    // If we are on the login page, send them to the homepage.
                    if (match.path === "/user/login") {
                        history.push("/");
                    } else {
                        // Otherwise, check if we are logged in which will re-render the current page.
                        checkLogin();
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    message.warning(extractErrorMessage(error));
                });
        },
        [checkLogin, history, match],
    );

    if (isLoggedIn) {
        return (
            <p>
                You are already logged in, please <a href="/">click here</a>.
            </p>
        );
    }

    return (
        <Form
            onFinish={onSubmit}
            onFinishFailed={error =>
                message.warning("Please complete all fields")
            }
            name="login_form"
        >
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: "Please enter your email address.",
                    },
                ]}
                initialValue=""
            >
                <Input
                    prefix={
                        <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="email"
                    placeholder="Email"
                    autoComplete="username"
                    name="email"
                    aria-label="email"
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please enter your password.",
                    },
                ]}
                initialValue=""
            >
                <Input
                    prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    name="password"
                    aria-label="password"
                />
            </Form.Item>

            <FormActions>
                <Link to="/user/password">Reset password</Link>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    name="login"
                >
                    Login
                </Button>
            </FormActions>
        </Form>
    );
};

export default withRouter(LoginForm);
export { LoginForm as _LoginForm };
