import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  // Autofill background color styling
  :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .ant-table-tbody .ck-editor {
    width: 99%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  // Remove transitions from ant design tabs
  .ant-tabs-tab {
    transition: none !important;
  }

  .ck.ck-presence-list {
    --ck-user-avatar-size: 32px;
    --ck-user-avatar-background: ${props => props.theme.primaryColor};
  }

  /* Offsets name due to font */
  .ck.ck-user__name {
    margin-top: 4px;
  }

  .ck.ck-presence-list__balloon {
    --ck-user-avatar-size: 32px;
    --ck-user-avatar-background: ${props => props.theme.primaryColor};
  }

  .ck.ck-presence-list__balloon .ck.ck-presence-list__dropdown-list-wrapper {
    --ck-presence-list-dropdown-list-min-width: 100px;
    --ck-presence-list-dropdown-list-max-width: 150px;
  }  

  .ck.ck-balloon-panel {
    --ck-user-avatar-background: ${props =>
        props.theme.primaryColor} !important;
    --ck-color-comment-background: ${props =>
        props.theme.lighterGrey} !important;
    --ck-color-comment-separator: ${props => props.theme.lightGrey} !important;
    
    .ck-editor__editable_inline {
      min-height: unset;
    }
  }

  body {
    font-family: 'benton-sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
    background: ${props => props.theme.lighterGrey} !important;
    height: 100%;
  }

  blockquote {
    font-size: 1.5em;
    width: 60%;
    border-left: 8px solid ${props => props.theme.primaryColor} !important;
    margin: 16px 0;
    padding: 1.2em 30px 1.2em 75px !important;
    position: relative;
    background: #EFEFEF;
    line-height: 1;

    &::before {
      font-family: Arial, Helvetica, sans-serif;
      content: "\\201C";
      color: #AAA;
      font-size: 4em;
      position: absolute;
      left: 20px;
      top: 5px;
    }

    &::after {
      content: "";
    }

    p {
      margin: 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.darkGrey};
  }

  p {
    color: ${props => props.theme.darkGrey};
  }

  a, .link {
    color: ${props => props.theme.blueGrey};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.greyBlack};
      text-decoration: none;
    }
  }

  .ant-table-row .ant-table-cell a:hover, .ant-pagination-item a, a.ant-btn {
    text-decoration: none;
  }

  .hide-link-styles a {
    text-decoration: none;
  }
  a.hide-link-styles {
    text-decoration: none;
  }


  h2 {
    margin-bottom: 16px;
  }

  .default-color {
    color: ${props => props.theme.darkGrey} !important;
    > * {
      color: ${props => props.theme.darkGrey} !important;
    }
  }

  .ant-avatar-string {
    cursor: default;
  }

  .hoverable {
    &:hover, .ant-avatar-string {
      cursor: pointer;
    }
  }

  .primary-color {
    color: ${props => props.theme.primaryColor};
  }

  .icon-large {
    font-size: 22px;
  }

  .icon-small {
    font-size: 11px;
  }

  .drop-over-downward,
  .drop-over-upward {
    border-top: 2px dotted blue;
  }

  .ck-editor__editable_inline {
    min-height: 300px;
  }

  .easyimage img {
    max-width: 100%;
  }

  .cke_top {
    position: sticky !important;
    top: 0 !important;
  }

  // Ant modal fix.
  .ant-modal-header {
    padding-right: 40px !important;
  }

  // Change z-index from the antd default
  .ant-popover {
    z-index: 1900;
  }
  .ant-dropdown {
    z-index: 999
  }

  // Moves z-index
  .ant-image-preview-wrap {
    z-index: 9999999999;
  }
`;

export default GlobalStyles;
