import { Input as AntInput } from "antd";
import styled from "styled-components";

const Input = styled(AntInput)`
    && {
        height: 36px;
    }
`;

export default Input;
