import React from "react";
import { Layout } from "antd";
import Header from "./Header";
import styled from "styled-components";
import ErrorBoundary from "../components/Common/ErrorBoundary";
import VerticalNav from "./Menu/VerticalNav";
import Media from "react-media";

const CustomLayout = styled(Layout)`
    background: #f5f5f5 !important;

    @media (min-width: 760px) {
        margin-left: 80px;
    }
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

interface Props {
    activeMenuItem?: string;
}

const AppLayout: React.FC<Props> = ({ activeMenuItem, children }) => {
    return (
        <div>
            <Media query="(min-width: 760px)">
                <VerticalNav activeMenuItem={activeMenuItem} />
            </Media>

            <CustomLayout>
                <Header />
                <ErrorBoundary>
                    <PageWrapper>{children}</PageWrapper>
                </ErrorBoundary>
            </CustomLayout>
        </div>
    );
};

export default AppLayout;
