import React from "react";
import { getCurrentUser } from "../selectors";
import { compose } from "redux";
import { connect } from "react-redux";

const CurrentUserComponent = WrappedComponent => {
    return props => {
        return <WrappedComponent {...props} />;
    };
};

const mapStateToProps = state => {
    return {
        currentUser: getCurrentUser(state),
    };
};

const withCurrentUser = compose(connect(mapStateToProps), CurrentUserComponent);

export default withCurrentUser;
