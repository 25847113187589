// turns paragraphs into double newlines, break rules into single newlines, and strips all other html tags

export const htmlToText = html =>
    html
        .replace(/<br>/g, "\n")
        .replace(/<\/p>\s*<p>/g, "\n\n")
        .replace(/(<[^>]+>)|(&nbsp;)/g, "");

// replace double newlines with paragraphs, single newlines with break rules

export const textToHtml = text =>
    `<p>${text.replace(/\n\s*\n\s*/g, "</p><p>").replace(/\n/g, "<br>")}</p>`;
