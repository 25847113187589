import { makeGetApiCall, makePatchApiCall, makePostApiCall } from "./api";
import { getRequestKey } from "./challenges";
import { IMAGE_LARGE_CONSUMER_ID } from "../consumers";
import encodeObjectToQueryString from "../helpers/encodeObjectToQueryString";

export function loadCompetitions(filters = {}) {
    return function (dispatch, getState) {
        let requestKey = getRequestKey("competitions");

        filters["filter[is_archived]"] = 0;
        let query = {
            include: "task_template,image",
            "sort[sort-created][path]": "changed",
            "sort[sort-created][direction]": "DESC",
            "filter[tasks.entity.is_archived]": "0",
            consumerId: IMAGE_LARGE_CONSUMER_ID,
            ...filters,
        };

        let queryString = encodeObjectToQueryString(query);

        return makeGetApiCall(
            dispatch,
            requestKey,
            "/jsonapi/task_workflow/competition?" + queryString,
        );
    };
}

export function createCompetition(data, playbookId = null) {
    let uri = "/jsonapi/task_workflow/competition";
    if (playbookId !== null) {
        uri += "?playbookId=" + playbookId;
    }

    return function (dispatch, getState) {
        return makePostApiCall(dispatch, "CREATE_COMPETITION", uri, data);
    };
}

export function updateCompetition(competition) {
    return function (dispatch, getState) {
        let requestData = {
            data: competition,
        };

        // Protected fields are removed from all requests.
        ["drupal_internal__id", "changed"].forEach(
            field => delete requestData.data.attributes[field],
        );

        return makePatchApiCall(
            dispatch,
            "UPDATE_COMPETITION",
            "/jsonapi/task_workflow/competition/" + competition.id,
            requestData,
        );
    };
}
