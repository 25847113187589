import React, { useEffect, useState } from "react";
import setPageTitle from "../hoc/setPageTitle";
import LoginForm from "../Forms/LoginForm";
import BasicPage from "../layouts/BasicPage";
import getConfig from "../instance-config";

const LoginPage = ({ checkLogin, isLoggedIn }) => {
    const [canUseLocalStorage, setCanUseLocalStorage] = useState(true);

    useEffect(() => {
        try {
            localStorage.setItem("crowd_storage_test", "1");
        } catch (e) {
            setCanUseLocalStorage(false);
        }

        // If we have a SSO URL and no query parameters then we automatically redirect.
        if (
            !isLoggedIn &&
            getConfig().sso_enabled &&
            window.location.search.length === 0
        ) {
            const timeout = setTimeout(() => {
                window.location = getConfig().sso_url(
                    window.location.pathname +
                        window.location.search +
                        window.location.hash,
                );
            }, 2000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isLoggedIn]);

    if (!canUseLocalStorage) {
        return (
            <BasicPage>
                <h2>Browser Issues</h2>
                <p>
                    It looks like your browser doesn't support using
                    localStorage. Please contact your system administrator.
                    There is more information available here:{" "}
                    <a href="https://dev.leanix.net/changelog/access-denied-with-internet-explorer">
                        https://dev.leanix.net/changelog/access-denied-with-internet-explorer
                    </a>
                </p>
            </BasicPage>
        );
    }

    return (
        <BasicPage>
            <h2>Login</h2>
            {getConfig().sso_enabled && window.location.search.length === 0 ? (
                <div>You are been redirected to the Single Sign On service</div>
            ) : (
                <LoginForm checkLogin={checkLogin} isLoggedIn={isLoggedIn} />
            )}
        </BasicPage>
    );
};

export default setPageTitle(LoginPage, "Login");
