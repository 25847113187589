import { connect } from "react-redux";
import { getUserPermissions } from "../selectors";

export function hasPermission(allPermissions, permission) {
    if (Array.isArray(permission)) {
        let found = false;
        permission.forEach(p => {
            if (hasPermission(allPermissions, p)) {
                found = true;
            }
        });
        return found;
    }
    return allPermissions.includes(permission);
}

const HasPermission = ({
    permissions,
    permission,
    children,
    alternate = null,
}) => {
    return hasPermission(permissions, permission) ? children : alternate;
};

const mapStateToProps = state => {
    return {
        permissions: getUserPermissions(state, state.appState.currentUser),
    };
};

let Component = connect(mapStateToProps)(HasPermission);
Component.displayName = "HasPermission";
export default Component;
