import React from "react";
import { DatePicker as DatePickerAnt } from "antd";
import moment from "moment";

// We collect value here so we don't forward it to ant
const DatePicker = React.forwardRef(
    ({ defaultValue, onChange, value, ...props }, ref) => {
        if (!defaultValue && value) {
            defaultValue = value;
        }

        if (!defaultValue) {
            defaultValue = null;
        } else {
            if (typeof defaultValue === "string") {
                defaultValue = moment(defaultValue);
            } else if (typeof defaultValue === "number") {
                defaultValue = moment.unix(defaultValue);
            }
        }

        return (
            <DatePickerAnt
                ref={ref}
                format={"DD/MM/YYYY"}
                defaultValue={defaultValue}
                onChange={e => {
                    onChange(e ? e.format() : null);
                }}
                {...props}
            />
        );
    },
);

export default DatePicker;
