import React from "react";
import { connect } from "react-redux";
import { Avatar as AntdAvatar, Tooltip } from "antd";
import { stringToColor, nameToInitials } from "../helpers";
import { getRelationship } from "../selectors";

// Using this instead of react-country-flag as a src url is needed instead of a componenet
const FLAG_CDN =
    "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/";

const Avatar = ({
    user,
    size = "small",
    color,
    tooltip = true,
    nameSuffix = "",
    placement = "top",
    image,
    style = {},
    ...props
}) => {
    if (!user) {
        return null;
    }

    if (image && image.links.thumbnail_cropped) {
        return (
            <Tooltip
                placement={placement}
                title={tooltip ? user.attributes.display_name + nameSuffix : ""}
            >
                <AntdAvatar
                    size={size}
                    src={image.links.thumbnail_cropped.href}
                    style={style}
                    {...props}
                />
            </Tooltip>
        );
    }

    if (user.attributes.country_code) {
        return (
            <Tooltip
                placement={placement}
                title={tooltip ? user.attributes.country : ""}
            >
                <AntdAvatar
                    style={style}
                    size={size}
                    {...props}
                    src={`${FLAG_CDN}${user.attributes.country_code.toLowerCase()}.svg`}
                ></AntdAvatar>
            </Tooltip>
        );
    }

    return (
        <Tooltip
            placement={placement}
            title={tooltip ? user.attributes.display_name + nameSuffix : ""}
        >
            <AntdAvatar
                style={{
                    flexShrink: 0,
                    backgroundColor:
                        color || stringToColor(user.attributes.display_name),
                    lineHeight: size === "small" ? "26px" : undefined,
                    ...style,
                }}
                size={size}
                {...props}
            >
                {nameToInitials(user.attributes.display_name)}
            </AntdAvatar>
        </Tooltip>
    );
};

const mapStateToProps = (state, props) => {
    const { user } = props;
    return {
        image: getRelationship(state, "field_picture", user),
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
export { Avatar as _Avatar };
